define(['app', 'componentHelper'], function(app, componentHelper) {

  const productThirdPartyStoreFinder = function() {
    const component = {};

    const _config = {
      selectors: {
        productThirdPartyStoreFinder: '[data-component=productThirdPartyStoreFinder]',
        productThirdPartyStoreFinderButton: '.tpssAvailability_button_default',
        productThirdPartyStoreFinderModal: '.productThirdPartyStoreFinderModal'
      },
      classNames: {
        modalDialogShow: 'modal_dialog-show',
      }
    };

    const _init = (element) => {
      component.element = element;
      component.addEventListener();

      return component;
    };

    const _addEventListener = () => {
      const productThirdPartyStoreFinderButton = document.querySelector(component.config.selectors.productThirdPartyStoreFinderButton);

      if(productThirdPartyStoreFinderButton) {
        productThirdPartyStoreFinderButton.addEventListener('click', component.openProductThirdPartyStoreFinderModal);
      }
    };

    const _openProductThirdPartyStoreFinderModal = () => {
      window.dataLayer.push({
        event: "elysiumEvent",
        eventData: {
          eventCategory: "Product Fulfilment",
          eventAction: "AvailabilityCheck",
          eventLabel: document.querySelector('[data-product-name=title]').textContent,
          eventLabelValue: "",
          eventPage: window.location.pathname
        },
      })

      let productThirdPartyStoreFinderModal = document.querySelector(component.config.selectors.productThirdPartyStoreFinderModal);
      if (productThirdPartyStoreFinderModal !== null) {
        const modal = productThirdPartyStoreFinderModal.parentElement.parentElement;
        modal.classList.add(component.config.classNames.modalDialogShow);
        productThirdPartyStoreFinderModal.parentElement.parentElement.style.display = 'flex';
      }
    };

    component.config = _config;
    component.init = _init;
    component.addEventListener = _addEventListener;
    component.openProductThirdPartyStoreFinderModal = _openProductThirdPartyStoreFinderModal;
    return component;
  };

  return productThirdPartyStoreFinder;
});
